import { Box } from "@mui/material"
import { ZonaSegmentoFilter } from "../components/ZonaSegmentoFilter"
import { useEffect, useState } from "react"
import { NavBar } from "../components/NavBar"
//import { PowerBIEmbed } from "powerbi-client-react"
//import { models } from "powerbi-client"
import { useAccount, useMsal } from "@azure/msal-react"


export const Home = () => {
    // eslint-disable-next-line
    const [urlFilter, setUrlFilter] = useState('')
    const [sectorFilter, setSectorFilter] = useState(window.localStorage.getItem('sector') ? window.localStorage.getItem('sector') : 'Sector')
    const [zonaFilter, setZonaFilter] = useState(window.localStorage.getItem('zona') ? window.localStorage.getItem('zona') : 'Nacional')
    const [fromFilter, setFromFilter] = useState(window.localStorage.getItem('from'))
    const [toFilter, setToFilter] = useState(window.localStorage.getItem('to'))

    // eslint-disable-next-line
    const { instance, accounts, inProgress } = useMsal();
    const account = useAccount(accounts[0] || {});
    // eslint-disable-next-line
    const [accessToken, setAccessToken] = useState(null);
    console.log(accessToken)

    useEffect(() => {
        setUrlFilter("&filter=Segmentos_x0020_Filter/Filter eq '" + sectorFilter + "' and Zonas_x0020_Filter/Filter eq '" + zonaFilter + "'")
        console.log(account)
        if (account) {
            instance.acquireTokenSilent({
                scopes: ["User.Read","https://analysis.windows.net/powerbi/api/Report.Read.All","https://analysis.windows.net/powerbi/api/Dataset.Read.All"],
                account: account,
            }).then((response) => {
                if(response) {
                    var token = response.accessToken
                    setAccessToken(token)
                    // console.log(accessToken)
                    fetch("https://api.powerbi.com/v1.0/myorg/GenerateToken", {
                        method: "post",
                        headers: {
                            "Authorization": "Bearer "+token,
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify({
                            reports: [
                                {id: '4d583354-da84-4102-8c5a-08d71a44c22d'}
                            ]
                        })
                    }).then((response) => {
                        console.log(response)
                    })
                }
            });
        }
    }, [sectorFilter, zonaFilter, fromFilter, toFilter, account, instance])

    window.addEventListener('storage', () => {
        setSectorFilter(window.localStorage.getItem('sector'))
        setZonaFilter(window.localStorage.getItem('zona'))
        setFromFilter(window.localStorage.getItem('from'))
        setToFilter(window.localStorage.getItem('to'))
    })

    return (<>
        <NavBar />
        <Box className="main">
            <ZonaSegmentoFilter />
            <iframe
                className="pbi-frame"
                title="Indicadores"
                src={"https://app.powerbi.com/reportEmbed?reportId=4d583354-da84-4102-8c5a-08d71a44c22d&autoAuth=true&ctid=6ee19001-d0c4-45f8-af8b-ff00f16d07e1&pageName=ReportSection7d7870505ae00ac08168&navContentPaneEnabled=false" + urlFilter}
                allowFullScreen={true}
                style={{ border: 'none', backgroundColor: 'transparent', flexGrow: 1 }}
            />


            {/* <PowerBIEmbed 
                embedConfig={{
                   type: 'report',
                   id: '4d583354-da84-4102-8c5a-08d71a44c22d',
                   embedUrl: 'https://app.powerbi.com/reportEmbed?reportId=4d583354-da84-4102-8c5a-08d71a44c22d&groupId=372b60ea-229b-4090-982d-4809129aabcf&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVNPVVRILUNFTlRSQUwtVVMtcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQiLCJlbWJlZEZlYXR1cmVzIjp7InVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlfX0%3d',
                   accessToken: accessToken,
                   tokenType: models.TokenType.Aad
                }} */}
            
            {/* /> */}

            <img src="/images/banner.png" style={{ width: '100vw', position: 'fixed', bottom: 0, left: 0 }} alt="City Banner" />
        </Box>
        {/* <QNAButtom /> */}
    </>)
}