import { Box, ToggleButtonGroup, ToggleButton, Typography } from "@mui/material"
// import dayjs from "dayjs";
import { useState, useEffect } from "react";


export const ZonaSegmentoFilter = () => {
    const [sectorFilter, setSectorFilter] = useState(window.localStorage.getItem('sector') ? window.localStorage.getItem('sector') : 'Sector')
    const [zonaFilter, setZonaFilter] = useState(window.localStorage.getItem('zona') ? window.localStorage.getItem('zona') : 'Nacional')
    // const [fromFilter, setFromFilter] = useState(window.localStorage.getItem('from') ? window.localStorage.getItem('from') : dayjs().startOf('month'))
    // const [toFilter, setToFilter] = useState(window.localStorage.getItem('to') ? window.localStorage.getItem('to') : dayjs().endOf('month'))

    useEffect(() => {
        localStorage.setItem('sector', sectorFilter)
        localStorage.setItem('zona', zonaFilter)
        // localStorage.setItem('from', fromFilter)
        // localStorage.setItem('to', toFilter)
        window.dispatchEvent(new Event("storage"))
    }, [sectorFilter, zonaFilter])

    const handleSectorChange = (e, value) => {
        if (value !== null) { setSectorFilter(value) }
    }
    const handleZonaChange = (e, value) => {
        if (value !== null) { setZonaFilter(value) }
    }
    // const handleFromChange = (value, e) => {
    //     setFromFilter(value['$d'])
    // }
    // const handleToChange = (value, e) => {
    //     setToFilter(value['$d'])
    // }    

    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
            {/* <Box>
                <DatePicker 
                    slotProps={{ textField: { size: 'small' } }} 
                    label="Desde" 
                    sx={{ width: '150px', marginRight: 2 }} 
                    onChange={handleFromChange}
                    defaultValue={dayjs(fromFilter)}
                />
                <DatePicker 
                    slotProps={{ textField: { size: 'small' } }} 
                    label="Hasta" 
                    sx={{ width: '150px' }} 
                    onChange={(handleToChange)}
                    defaultValue={dayjs(toFilter)}
                />
            </Box>             */}
            <ToggleButtonGroup
                value={zonaFilter}
                exclusive
                onChange={handleZonaChange}
            >
                <ToggleButton value="Nacional">
                    <Typography variant="p" sx={{ fontSize: '.6rem', fontWeight: 'bold' }}>Nacional</Typography>
                </ToggleButton>
                <ToggleButton value="Pacífico">
                    <Typography variant="p" sx={{ fontSize: '.6rem', fontWeight: 'bold' }}>Pacífico</Typography>
                </ToggleButton>
                <ToggleButton value="Sureste">
                    <Typography variant="p" sx={{ fontSize: '.6rem', fontWeight: 'bold' }}>Sureste</Typography>
                </ToggleButton>
                <ToggleButton value="Noreste">
                    <Typography variant="p" sx={{ fontSize: '.6rem', fontWeight: 'bold' }}>Noreste</Typography>
                </ToggleButton>
                <ToggleButton value="Centro">
                    <Typography variant="p" sx={{ fontSize: '.6rem', fontWeight: 'bold' }}>Centro</Typography>
                </ToggleButton>
            </ToggleButtonGroup>

            <ToggleButtonGroup
                value={sectorFilter}
                exclusive
                onChange={handleSectorChange}
                sx={{ ml: 4 }}
            >
                <ToggleButton value="Sector">
                    <Typography variant="p" sx={{ fontSize: '.6rem', fontWeight: 'bold' }}>Sector</Typography>
                </ToggleButton>
                <ToggleButton value="Construcción">
                    <Typography variant="p" sx={{ fontSize: '.6rem', fontWeight: 'bold' }}>Construcción</Typography>
                </ToggleButton>
                <ToggleButton value="Industrial y Transformación">
                    <Typography variant="p" sx={{ fontSize: '.6rem', fontWeight: 'bold' }}>Industrial</Typography>
                </ToggleButton>
                <ToggleButton value="Distribución">
                    <Typography variant="p" sx={{ fontSize: '.6rem', fontWeight: 'bold' }}>Distribución</Typography>
                </ToggleButton>
                <ToggleButton value="Infraestructura y Gobierno">
                    <Typography variant="p" sx={{ fontSize: '.6rem', fontWeight: 'bold' }}>Infraestructura</Typography>
                </ToggleButton>
            </ToggleButtonGroup>


        </Box>
    )
}