import './App.css';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'


import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { Routing } from './pages/Routing';

const pca = new PublicClientApplication({
	auth: {
		// secretId: "lxK8Q~GVdssecc5XoW5HSMe5vIaOaKTPuOJI3cxN"
		clientId: "d5b13502-c09d-4b3b-8483-8ce2cb4656ca",
		authority: "https://login.microsoftonline.com/6ee19001-d0c4-45f8-af8b-ff00f16d07e1",
		redirectUri: 'https://datacrete.cemex.com/login',
	},
})
await pca.initialize();


function App() {

  return (
	<MsalProvider instance={pca}>
	<LocalizationProvider dateAdapter={AdapterDayjs}>
	<Routing />
	</LocalizationProvider>
	</MsalProvider>
  );
}

export default App;
