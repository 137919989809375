import { Box } from "@mui/material"
import { NavBar } from "../components/NavBar"

export const QNA = () => {

    return (<>
        <NavBar />
        <Box className="main">
            <iframe 
                title="Indicadores" 
                src={"https://app.powerbi.com/reportEmbed?reportId=8dd21e46-f531-4abc-a388-97526a131346&autoAuth=true&ctid=6ee19001-d0c4-45f8-af8b-ff00f16d07e1&pageName=ReportSection24d8e8a990064a110363&navContentPaneEnabled=false&filterPaneEnabled=false"}
                allowFullScreen={true}
                style={{border:'none', backgroundColor:'transparent', flexGrow: 1}}
            />
        </Box>
    </>)
}