import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { useIsAuthenticated, useMsal } from '@azure/msal-react';

import { Home } from './Home';
import { Report } from './Report';
import { Login } from './Login';
import { QNA } from './QNA';


export const Routing = () => {
    const isAuthenticated = useIsAuthenticated()
    const { inProgress } = useMsal();
    
    return <BrowserRouter>
    {inProgress !== 'startup' ?
        <Routes>
            
                <Route path="/" exact element={isAuthenticated ? <Home /> : <Navigate to= '/login' />} />
                <Route path="/report/:name/:ctid/:id/:page" exact element={isAuthenticated ? <Report /> : <Navigate to='/login' />} />
                <Route path="/QNA" exact element={isAuthenticated ? <QNA /> : <Navigate to='/login' />} />
                <Route path="/login" exact element={!isAuthenticated ? <Login /> : <Navigate to='/' />} />            
        </Routes>
    : 'Loading...'}
    </BrowserRouter>
}