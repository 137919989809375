import { Box, Button, Paper, Typography } from "@mui/material"
import { useMsal } from "@azure/msal-react"
// import bgImage from '/images/bg.png']

const styles = {
    loginBox: {
        // flexGrow: 1,
        backgroundColor: 'rgba(255,255,255,.95)',
        width: '500px',
        maxWidth: '90%',
        height: '300px',
        marginTop: '-10rem',
        padding: '2rem',
        textAlign: 'center',
        display: 'flex',
        flexFlow: 'column'
    },
    loginLogo: {
        width: '250px',
        marginLeft: 'auto',
        marginRight: 'auto',
        display: 'block'
    }
}

export const Login = () => {
    // eslint-disable-next-line
    const { instance, accounts, inProgress } = useMsal();

    return (<Box sx={{ width: '100vw', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundImage: `url('/images/bg3.png')`, backgroundSize:'cover'}}>
        {/* <img src="/images/topLeft.png" style={{width: '400px', position: 'fixed', top:0, left:-82}} /> */}
        {/* <img src="/images/botRight.png" style={{width: '500px', position: 'fixed', bottom:-75, right:-185}} /> */}
        <Paper sx={styles.loginBox} elevation={6}>
            <Box sx={{flex: '0 1 auto'}}>
                <img src="/images/Logo.png" alt="Logo CEMEX" style={styles.loginLogo} />
                <Typography variant="h5">¡Bienvenido(a)!</Typography>
                <Typography variant="subtitle1">DATACRETO</Typography>
            </Box>
            <Box sx={{flex: '1 1 auto', display:'flex', alignItems:'center', justifyContent:'center'}}>
                <Button variant="outlined" size="large" onClick={() => instance.loginPopup({scopes:["User.Read","https://analysis.windows.net/powerbi/api/Report.Read.All","https://analysis.windows.net/powerbi/api/Dataset.Read.All"]})}>Iniciar Sesion</Button>
            </Box>
        </Paper>
    </Box>)
}