import { Box } from "@mui/material"
import { useLocation, useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import { ZonaSegmentoFilter } from "../components/ZonaSegmentoFilter"
import { NavBar } from "../components/NavBar"


export const Report = () => {
    const {name, ctid, id, page} = useParams()
    const query = new URLSearchParams(useLocation().search)

    const [urlFilter, setUrlFilter] = useState('')
    const [sectorFilter, setSectorFilter] = useState(window.localStorage.getItem('sector') ? window.localStorage.getItem('sector') : 'Sector')
    const [zonaFilter, setZonaFilter] = useState(window.localStorage.getItem('zona') ? window.localStorage.getItem('zona') : 'Nacional')
    const [fromFilter, setFromFilter] = useState(window.localStorage.getItem('from'))
    const [toFilter, setToFilter] = useState(window.localStorage.getItem('to'))

    useEffect(() => {
        setUrlFilter("&filter=Segmentos_x0020_Filter/Filter eq '" + sectorFilter + "' and Zonas_x0020_Filter/Filter eq '" + zonaFilter + "'")
    }, [sectorFilter, zonaFilter, fromFilter, toFilter])

    window.addEventListener('storage', ()=>{
        setSectorFilter(window.localStorage.getItem('sector'))
        setZonaFilter(window.localStorage.getItem('zona'))
        setFromFilter(window.localStorage.getItem('from'))
        setToFilter(window.localStorage.getItem('to'))        
    })

    return (<>
        <NavBar reportName={name} />
        <Box className="main">
            {query.get('hideFilters') ? '' : <ZonaSegmentoFilter />}
            <iframe 
                title="Indicadores" 
                src={"https://app.powerbi.com/reportEmbed?reportId="+id+"&autoAuth=true&ctid="+ctid+"&pageName="+page+"&navContentPaneEnabled=false&filterPaneEnabled=false" + urlFilter}
                allowFullScreen={true}
                style={{border:'none', backgroundColor:'transparent', flexGrow: 1}}
            />
        </Box>
        {/* <QNAButtom /> */}
    </>)
}